export const speakers_not_fount = {
    "FPOe": [
        "Achatz Anna Elisabeth",
        "Amesbauer Hannes, BA",
        "Angerer Erwin",
        "Apfelbeck Ute",
        "Asp\u00f6ck Robert, Dr.",
        "Barm\u00fcller Thomas, Mag.",
        "Bauer Gerhard, Ing.",
        "Bauer Holger, Dipl.-Kfm.",
        "Bekavac-Ramsbacher Helena siehe Ramsbacher Helena",
        "Belakowitsch Dagmar, Dr.",
        "Berger Ricarda",
        "Blasisker Josef",
        "Bl\u00fcnegger Anton",
        "Breitenfeld-Paph\u00e1zy Sylvia, Dr., MBA siehe Paph\u00e1zy Sylvia, Dr., MBA",
        "Broesigke Tassilo, Dr.",
        "Brugger Bernd",
        "Br\u00fcckl Hermann",
        "Burket Ilse",
        "B\u00f6hacker Hermann",
        "B\u00f6hmdorfer Dieter, Dr.",
        "B\u00f6sch Reinhard Eugen, Dr.",
        "Dillersberger Siegfried, Dr.",
        "Egghart Robert",
        "Eigruber Hermann",
        "Fallent Gerhard, Ing.",
        "Fischl Harald",
        "Freiga\u00dfner Evelyn",
        "Frischenschlager Friedhelm, Dr.",
        "F\u00fcrst Susanne, Dr.",
        "Gartelgruber Carmen siehe Schimanek Carmen",
        "Gerstner Peter",
        "Grabher-Meyer Walter",
        "Graf Herbert L., Ing.",
        "Graf Martin, Dr. siehe Graf Martin, Mag. Dr.",
        "Gratzer Bernhard",
        "Gredler Willfried, Dr.",
        "Gudenus Johann, Mag., M.A.I.S.",
        "Gudenus John, Mag.",
        "Gugerbauer Norbert, Dr.",
        "G\u00f6tz Alexander, Dipl.-Ing. DDr.",
        "Hackl Heinz-Peter, Ing.",
        "Hafenecker Christian, MA",
        "Haider J\u00f6rg, Dr.",
        "Hanreich Georg, Dipl.-Ing.",
        "Hartinger-Klein Beate, Mag.",
        "Herbert Werner",
        "Hetzl Gerhard, Mag.",
        "Hintermayer Josef",
        "Hochsteiner Heinz, Dipl.-Kfm.",
        "Hornegger Franz",
        "Huber Alois",
        "H\u00f6bart Christian, Ing.",
        "H\u00f6binger-Lehrer Liane, Dr.",
        "H\u00fcbner Johannes, Dr.",
        "Jenewein Hans-J\u00f6rg, MA",
        "Josseck Helmuth, Dipl.-Vw.",
        "Jury Josef",
        "Kabas Hilmar, Mag.",
        "Kainz Alois",
        "Kandutsch J\u00f6rg, Dr.",
        "Kaniak Gerhard, Mag.",
        "Karlsb\u00f6ck Andreas F., Dr.",
        "Kindl Wilhelm",
        "Kitzm\u00fcller Anneliese",
        "Klein Anneliese",
        "Klinger Wolfgang, Ing.",
        "Knerzl Anton",
        "Koller Franz",
        "Kos Wilhelm, Dr.",
        "Krauss Maximilian",
        "Kr\u00fcger Michael, Dr.",
        "Kr\u00fcnes Helmut, Dipl.-Ing. Dr.",
        "Kumpitsch G\u00fcnther, Mag.",
        "Kunasek Mario",
        "K\u00f6nigshofer Werner, DDr.",
        "Lafer Franz",
        "Lasar David",
        "Lausch Christian",
        "Lautenschlager Hartmann",
        "Leutg\u00f6b Karl",
        "Linser Franz, Dr.",
        "Lintl Jessi, Dr.",
        "Mahnert Klaus",
        "Marolt Heinz Anton",
        "Mautner Markhof Georg, Dipl.-Kfm.",
        "Mayerhofer Leopold",
        "Meisinger Josef",
        "Mei\u00dfl Othmar",
        "Melter Werner",
        "Mentil Hermann",
        "Mitterer Peter",
        "Mitterm\u00fcller Marialuise",
        "Murer Gerulf, Ing.",
        "M\u00f6lzer Wendelin",
        "M\u00fchlberghuber Edith",
        "M\u00fcller Hans",
        "Niederhuemer Wilhelm",
        "Nu\u00dfbaumer Wolfgang, Ing.",
        "Ortlieb Patrick",
        "Ortner Peter, Mag.",
        "Pawkowicz Rainer, Dipl.-Ing. Dr.",
        "Peter Friedrich",
        "Pewny Christian, Ing.",
        "Pfeifer Helfried, Dr.",
        "Pistotnig Jakob",
        "Pranckh Georg",
        "Praxmarer Karin, Mag.",
        "Preisinger Susanne, Dr.",
        "Pretterebner Hans",
        "Probst Friedrich",
        "Ragger Christian, Mag.",
        "Ramsbacher Helena",
        "Rauch Walter",
        "Reifenberger Volker, Ing. Mag.",
        "Reindl Hermann",
        "Riemer Josef A.",
        "Ries Christian",
        "Riess Susanne, Dr. siehe Riess-Passer Susanne, Dr.",
        "Rie\u00df Susanne",
        "Ruthofer Kurt, Dipl.-Kfm.",
        "R\u00f6hrs Konstanze, Mag.",
        "Salzl Stefan, Dr.",
        "Schandor Christian, Dipl.-Ing.",
        "Schartel Andrea Michaela",
        "Schellenbacher Thomas, Ing.",
        "Schender R\u00fcdiger, Mag.",
        "Scheuch Kurt, Ing.",
        "Scheuch Robert, Dipl.-Ing. Dr.",
        "Schimanek Carmen",
        "Schmid Michael, Dipl.-Ing.",
        "Schmidt Albert, Dr.",
        "Schmiedlechner Peter",
        "Schoettel-Delacher Irina, lic.oec. HSG",
        "Schrangl Philipp, Mag.",
        "Schreiner Erich L., Ing. Mag.",
        "Sch\u00f6ggl Leopold, Dipl.-Ing.",
        "Sch\u00f6ll Hans",
        "Sch\u00f6nhart G\u00fcnter",
        "Scrinzi Otto, Dr.",
        "Sevignani Hans",
        "Sodian Andreas",
        "Staffaneller Norbert",
        "Steger Norbert, Dr.",
        "Steger Petra",
        "Stendebach Max",
        "Stix Gerulf, Dipl.-Vw. Dr.",
        "Svazek Marlene, BA",
        "Tilg Wilfried",
        "Trenk Josef",
        "Trettenbrein Harald",
        "Tschank Markus, Dr.",
        "Unterreiner Heidemarie, Mag.",
        "Venier Mathias",
        "Wagner Petra",
        "Wassermann Sandra",
        "Wattaul Anton",
        "Weinmeier Wilhelm, Ing.",
        "Weiss Helmuth, Mag.",
        "Wenitsch Robert",
        "Winter Susanne, Dr.",
        "Wochesl\u00e4nder Jutta, Dr.",
        "Zechmann Heinrich, Dr.",
        "Zeillinger Gustav",
        "Zellot Roland",
        "van Tongel Emil, Mag. Dr."
    ],
    "BZOe": [
        "B\u00f6sch Reinhard Eugen, Dr.",
        "Jury Josef",
        "List Kurt",
        "Mitterm\u00fcller Marialuise",
        "Spadiut Wolfgang, Dr.",
        "Tadler Erich",
        "Wattaul Anton"
    ],
    "LIF-NEOS": [
        "Bernhard Michael",
        "Doppelbauer Karin, Dipl.-Ing.",
        "Griss Irmgard, Dr.",
        "Hoyos-Trauttmansdorff Douglas",
        "Krisper Stephanie, Dr.",
        "Mlinar Angelika, Mag. Dr., LL.M.",
        "Scherak Nikolaus, Dr., MA",
        "Vavrik Christoph, Mag."
    ],
    "OeVP": [
        "Aichhorn Karl",
        "Altenburger Erwin",
        "Amon Werner siehe Amon Werner, MBA",
        "Amtmann Heinrich, Ing.",
        "Arthold Josef",
        "Aubauer Gertrude, Mag.",
        "Auer Klaus Hubert, Dipl.-Ing.",
        "Babitsch Leopold, Dipl.-Ing.",
        "Bandion Karl",
        "Bassetti Luis, Dipl.-Kfm. Dr.",
        "Bauer Franz",
        "Bauer Franz, Dr.",
        "Baumgartner Angela",
        "Baumgartner-Gabitzer Ulrike, Dr.",
        "Bayer Johanna, Dipl.-Ing. Dr.",
        "Bayr Anton",
        "Bergmann Kurt",
        "Bergsmann Felix",
        "Berl Franz, Dipl.-Ing.",
        "Blenk Wolfgang, Dr.",
        "Bleyer Wilhelm",
        "Bock Fritz, Dr.",
        "Bogner-Strau\u00df Juliane, Mag. Dr.",
        "Brader Alfred, Mag. Dr.",
        "Brandl Franz",
        "Brandst\u00e4tter Jakob",
        "Breiteneder Johann",
        "Bruckmann Gerhart, Dr.",
        "Brunner Franz",
        "Brunner Karl",
        "Br\u00fcnner Christian, Dr.",
        "Burger Siegmund",
        "Burgstaller Paul",
        "Busek Erhard, Dr.",
        "B\u00f6ck-Greissau Josef",
        "B\u00f6hm Franz Xaver",
        "Cerny Theodor",
        "Cortolezis-Schlager Katharina, Mag.",
        "Dengler Josef",
        "Derfler Alois, Ing.",
        "Dernoscheg Karl-Heinz, Dr., MBA",
        "Deutschmann Valentin",
        "Diesner-Wais Martina",
        "Dinkhauser Josef",
        "Dittrich Karl, Ing.",
        "Ditz Johannes, Dr.",
        "Doppler Anton",
        "Drescher Martin",
        "Durchschlag Claudia",
        "Dworak Franz",
        "D\u00f6nmez Efgani, PMM",
        "Eder Sebastian, Dr.",
        "Eder-Gitschthaler Andrea, Dr.",
        "Ehgartner Hans",
        "Ehrenfried Anton",
        "Eichinger Johann",
        "Eichinger Karl",
        "Einwallner Thomas",
        "Eisenschenk Peter, Mag.",
        "El Habbassi Asdin, BA",
        "Ellmauer Matthias",
        "Engelberg Martin, Mag.",
        "Entner Richard",
        "Ermacora Felix, Dr.",
        "Ettmayer Wendelin, Dr.",
        "E\u00dfl Franz Leonhard",
        "Fachleutner Karl",
        "Felzmann Carina",
        "Ferrero-Waldner Benita, Dr.",
        "Fichtinger Angela",
        "Fiedler Kurt, Dipl.-Kfm. Dr.",
        "Figl Leopold, Dipl.-Ing. DDDr. h.c.",
        "Fink Ernst",
        "Fink Josef, Dr.",
        "Fink Pius, Dipl.-Ing.",
        "Finz Alfred, Dr.",
        "Fischer Leopold",
        "Fischer Rudolf Heinz, Ing.",
        "Fischler Franz, Dipl.-Ing. Dr.",
        "Flemming Marilies, Dr.",
        "Flicker Franz, Dipl.-Ing.",
        "Franz Anna",
        "Franz Georg",
        "Frauscher Helmut, Dipl.-Kfm. Dr.",
        "Freund Karl",
        "Friedl Karl",
        "Frieser Cordula, Mag.",
        "Frisch Anton",
        "Fritz Hermann",
        "Frizberg Gilbert, Dr.",
        "Frodl Karl",
        "Fr\u00fchwirth Josef, Dipl.-Ing. Dr.",
        "Fuchs Hans Georg, Dipl.-Ing.",
        "Fuchs Luis",
        "Fuhrmann Silvia, Mag. siehe Gr\u00fcnberger Silvia, Mag.",
        "F\u00fcrlinger Klaus, Mag.",
        "Gabriele Franz",
        "Gaigg Gerfrid, Dr.",
        "Gasperschitz Alfred, Dr.",
        "Gasser Hans, Dipl.-Ing.",
        "Gassner Johann, Ing.",
        "Gassner Martin",
        "Geischl\u00e4ger Robert, Dr.",
        "Gei\u00dfler Hermann, Dr.",
        "Gei\u00dflinger Ferdinand",
        "Gf\u00f6llner Alois",
        "Gierlinger Balthasar",
        "Gindler Anton",
        "Glaser Franz",
        "Glaser Karl",
        "Gorbach Alfons, Dr.",
        "Gorton Wilhelm, Dipl.-Kfm.",
        "Gradinger Rudolf, Ing.",
        "Graf Ferdinand",
        "Graf Robert",
        "Graf Rudolf",
        "Graf Tanja",
        "Graff Michael, Dr.",
        "Gram Heribert",
        "Grander Maria",
        "Grie\u00dfner Isidor",
        "Grillitsch Fritz",
        "Groi\u00df Werner, Ing. Mag.",
        "Gro\u00dfbauer Maria",
        "Gro\u00dfruck Wolfgang",
        "Gruber Hermann",
        "Gruber Josef, Dr.",
        "Gruber Karl",
        "Gruber Karl, Dipl.-Ing. Dr.",
        "Grubhofer Franz",
        "Grundemann-Falkenberg Ernst",
        "Gr\u00fcnberg Kira",
        "Gr\u00fcnberger Silvia, Mag.",
        "Gr\u00fcnsteidl Edmund, Dr.",
        "Gschnitzer Franz, Dr.",
        "Guggenberger Leopold, abs. iur.",
        "Gurtner Rudolf",
        "Guth Ernst",
        "G\u00f6dl Ernst, Mag.",
        "Haager Hans",
        "Hafner Hans, Dr.",
        "Hagenauer Johann",
        "Hagspiel Ludwig",
        "Hahn Friedrich",
        "Hahn Johannes, Dr.",
        "Haider Johann, Dr.",
        "Halder Jakob, Dr.",
        "Haller Anton",
        "Hammer Michael, Mag.",
        "Handel Max",
        "Hanger Andreas, Mag.",
        "Hans Josef",
        "Hartl Leopold",
        "Hartmann Eduard, Dipl.-Ing.",
        "Harwalik Adolf",
        "Hattmannsdorfer Johann",
        "Haubner Peter",
        "Haunschmidt Franz",
        "Hauser Walter, Dr.",
        "Heinzinger Walter",
        "Hei\u00df Regina",
        "Helbich Leopold, Ing.",
        "Hetzenauer Franz, Dr.",
        "Hietl Franz",
        "Himmelbauer Eva-Maria, BSc",
        "Hinterndorfer Fritz",
        "Hofeneder Hubert, Dr.",
        "Hofer Johann",
        "Hofinger Manfred, Ing.",
        "Hofstetter Karl, Ing.",
        "Hornek Erwin",
        "Horngacher Katharina",
        "Hosp Maria, Dr.",
        "Huainigg Franz-Joseph, Dr.",
        "Huber Hubert",
        "Hubinek Marga, Dr.",
        "Huemer Alois",
        "Hummer Josef",
        "Hurdes Felix, Dr.",
        "H\u00e4mmerle Rudolf, Dipl.-Ing.",
        "H\u00f6chtl Josef, Mag. Dr.",
        "H\u00f6finger Johann",
        "H\u00f6llerer Anna",
        "H\u00f6rl Franz",
        "H\u00fctl G\u00fcnther, Dipl.-Ing.",
        "Ikrath Peter Michael, Mag.",
        "Iro J\u00f6rg, Dr.",
        "Jachs Johanna, Mag.",
        "Jank Brigitte",
        "Jeitler-Cincelli Carmen, Mag., BA",
        "Kabesch Erich",
        "Kainz Christoph",
        "Kaiser Richard, Dipl.-Ing.",
        "Kammerhofer Johann",
        "Kampichler Franz",
        "Kapeller Norbert, Ing.",
        "Kapsreiter Gustav",
        "Karas Othmar, Dr., MBL-HSG",
        "Karas Othmar, Mag., MBL-HSG siehe Karas Othmar, Dr., MBL-HSG",
        "Karasek Franz, Dr.",
        "Kaufmann Martina, MMSc BA",
        "Kaufmann Paul, Dr.",
        "Kecht Anton",
        "Keimel Otto, Dipl.-Kfm. Dr.",
        "Keller Otto",
        "Kern Leopold",
        "Keuschnigg Georg",
        "Killisch-Horn Michael, Dipl.-Vw.",
        "Kinzl Karl",
        "Kirchbaumer Rebecca",
        "Kirchknopf Josef",
        "Klaus Josef, Dr.",
        "Klikovits Oswald",
        "Klomfar Helmut",
        "Knoll Josef",
        "Kohlmaier Herbert, Dr.",
        "Kolb Ernst, Dr.",
        "Koller Franz",
        "Koppensteiner Gerhard",
        "Koren Hanns, Dr.",
        "Koren Stephan, Dipl.-Vw. Dr.",
        "Korosec Ingrid",
        "Kortschak Franz, Ing.",
        "Kottulinsky Hans, Dipl.-Ing.",
        "Kotzina Vinzenz, Dr.",
        "Kowald Ludwig, Ing.",
        "Kraft Hermann",
        "Krainer Josef, Dr.",
        "Kranebitter Franz",
        "Kranzlmayr Otto, Dr.",
        "Krauland Peter, Dr.",
        "Kraus Josef",
        "Krempl Matthias",
        "Krenn Barbara",
        "Krippner Franz",
        "Kristofics-Binder Rudolf, Dipl.-Kfm.",
        "Krottendorfer Leopold",
        "Kr\u00f6ll Hermann",
        "Kugler Gudrun, Dr.",
        "Kulhanek Hans",
        "Kummer Franz",
        "Kummer Karl, Dr.",
        "Kunschak Leopold",
        "Kurz Sebastian",
        "Kurzbauer Johann",
        "Kuss-Bergner Angelika, BEd",
        "K\u00f6ck Ignaz",
        "K\u00f6nig Friedrich, Dipl.-Kfm. DDr.",
        "K\u00f6stinger Elisabeth",
        "K\u00f6\u00dfl G\u00fcnter",
        "K\u00fchberger Andreas",
        "Lach Franz, Dr.",
        "Lackner Josef, Dipl.-Vw. Dr.",
        "Lafer Karl",
        "Lakowitsch Karl",
        "Landgraf Konrad",
        "Landmann Paul",
        "Langreiter Hans, Mag.",
        "Lanner Sixtus, Dr.",
        "Lechner Franz, Dipl.-Ing. Dr.",
        "Ledolter Johann",
        "Leibenfrost Albert, Dr.",
        "Leimb\u00f6ck Ernst",
        "Leiner G\u00fcnther, Dr.",
        "Leisser Franz",
        "Leitner Alois, Dipl.-Ing. Dr.",
        "Leitner Peter",
        "Lentsch Edeltraud",
        "Letmaier Josef, Ing.",
        "Lettenbichler Josef, Mag.",
        "Lexer Reinhold",
        "Lichal Robert, Dr.",
        "Liechtenstein Vincenz, Dr.",
        "Lindinger Klaus, Ing., BSc",
        "Lins Herbert",
        "Linsbauer Franz",
        "Loos Johann",
        "Ludwig Eduard, Dr.",
        "Luhamer Josef",
        "Lussmann Hermann",
        "L\u00f6ffler Robert, Dipl.-Kfm.",
        "Machne Helga",
        "Machunze Erwin",
        "Mackowitz Rudolf",
        "Maderthaner Leopold, Ing.",
        "Mahrer Karl, BA",
        "Maier Ferdinand, Dr.",
        "Mairinger Max",
        "Maitz Karl, Dr.",
        "Maleta Alfred, Dr.",
        "Mandl Wilhelm",
        "Manndorff Ferdinand",
        "Marberger Karl",
        "Marchetti Nico",
        "Marek Christine",
        "Margaretha Eugen, Dr.",
        "Marktschl\u00e4ger Rudolf",
        "Marwan-Schlosser Rudolf",
        "Mathis Kurt, Ing.",
        "Matt Josef",
        "Maurer Hans, abs. iur.",
        "Mayer Ferdinand",
        "Mayer Ludwig",
        "Mayer Norbert",
        "Mayer Oskar, Dr.",
        "Mayer Peter",
        "Mayr Franz",
        "Mayrhofer Franz",
        "Mikesch Herta",
        "Mikola Frieda",
        "Minkowitsch Roland, Mag.",
        "Missethon Hannes, Dipl.-Ing.",
        "Mittendorfer Josef",
        "Mitterer Otto",
        "Molterer Josef",
        "Moretti Adelheid Irina",
        "Moser Eduard, Dr.",
        "Moser Franz",
        "Moser Sonja, Dr. siehe Moser-Starrach Sonja, Dr.",
        "Moser Wilhelmine",
        "Moser-Starrach Sonja, Dr.",
        "Murauer Walter",
        "Mussil Arthur, Dr.",
        "M\u00e4dl Jakob",
        "M\u00f6st Maria Elisabeth, Dipl.-Ing.",
        "M\u00fchlbachler Josef, Dipl.-Kfm. Mag.",
        "M\u00fcllner Viktor",
        "Nedwal Andreas",
        "Nehammer Karl, MSc",
        "Nemecz Alexander, Dr.",
        "Neudorfer Franz",
        "Neumann Johann",
        "Neuner Kurt, DDr.",
        "Nimmervoll Franz",
        "Niss Maria Theresia, Dr., MBA",
        "Oberhammer Aloys, Dr.",
        "Obrutschka Fritz",
        "Ofenauer Friedrich, Mag.",
        "Ofenb\u00f6ck Josef",
        "Ott Heinrich",
        "Ottenschl\u00e4ger Andreas",
        "Pack Jochen",
        "Paulitsch Alois, Dr.",
        "Paunovic Nadine, Dr.",
        "Pecher Martina, Mag.",
        "Pelikan Fritz, Dr.",
        "Pernter Hans, Dr.",
        "Pfurtscheller Elisabeth, Dipl.-Kffr. (FH)",
        "Piffl-Percevic Theodor, Dr.",
        "Pirker Hubert, Dr.",
        "Pischl Karl",
        "Plakolm Claudia",
        "Plassnik Ursula, Dr.",
        "Platter G\u00fcnther",
        "Polcar Fritz",
        "Prader Georg, Dr.",
        "Pranckh Georg",
        "Pra\u00dfl Michael",
        "Preineder Martin",
        "Prinke Franz, Dr. h.c.",
        "Prinz Nikolaus",
        "Prirsch Ferdinand",
        "Pr\u00f6ll Josef, Dipl.-Ing.",
        "Puntigam Alois, Dr.",
        "Puttinger G\u00fcnter, Dipl.-Kfm. Dr.",
        "P\u00f6tsch Johann",
        "Raab Julius, Ing. DDDr.",
        "Rabl-Stadler Helga, Dr.",
        "Rack Reinhard, Dr.",
        "Rainer Hermann",
        "Rapatz Robert, Dipl.-Ing.",
        "Rasinger Erwin, Dr.",
        "Rauch Johannes, Mag.",
        "Regensburger Franz",
        "Regler Roderich, Dipl.-Ing. Mag.",
        "Rehor Grete",
        "Reich Josef",
        "Reisetbauer Rudolf, Dr.",
        "Reisinger Heinrich",
        "Reiter Josef",
        "Rieder Hans",
        "Riedl Walter",
        "Riegler Josef, Dipl.-Ing.",
        "Riener Barbara",
        "Rochus Ottilie",
        "Rosenberger Alois, Dipl.-Ing.",
        "Roth Hans",
        "Roth Rupert, Dr.",
        "Rupp Josef",
        "Rupprechter Andr\u00e4, Dipl.-Ing.",
        "R\u00e4dler Johann",
        "R\u00f6dhammer Hans",
        "R\u00f6mer Alfred",
        "Sallinger Rudolf, Ing.",
        "Salzmann Gertraud, MMMag.",
        "Sandmeier Karl",
        "Sauer Willi",
        "Schaber Franz",
        "Schauer Erwin",
        "Scheff Otto, Dr.",
        "Scheibenreif Alois",
        "Scherrer Josef",
        "Scheucher-Pichler Elisabeth, Mag.",
        "Schiefermair Notburga",
        "Schindlbacher Ernst, Ing.",
        "Schittenhelm Dorothea",
        "Schlager Anton",
        "Schleinzer Karl, Dipl.-Ing. Dr.",
        "Schl\u00f6gl Manfred, Mag.",
        "Schmelz Heinrich, Dipl.-Ing. Dr.",
        "Schmidt Elisabeth",
        "Schmitzer Karl, Ing.",
        "Schmuckenschlager Johannes",
        "Schn\u00f6ll Stefan, Mag.",
        "Schorn Hildegard",
        "Schrefel Josef",
        "Schrott Dominik",
        "Schrotter Johann",
        "Schumy Vinzenz, Ing.",
        "Schuster Johann",
        "Schwarz Gabriela",
        "Schwarzb\u00f6ck Rudolf",
        "Schweinberger Johann",
        "Schweinhammer Johann",
        "Schweisgut Johannes",
        "Schwer Karl, Dr.",
        "Schwimmer Walter, Dr.",
        "Schw\u00e4rzler Erich, Ing.",
        "Sch\u00e4ffer Gerhard, Mag.",
        "Sch\u00f6ls Alfred",
        "Sch\u00f6nbauer Leopold, Dr.",
        "Sch\u00f6negger Bernd, Mag.",
        "Sch\u00f6pf Albert, Dr.",
        "Sebinger Hans",
        "Seidl Georg",
        "Seiner Anton",
        "Seiringer Karl",
        "Sieber Norbert",
        "Singer Johann",
        "Smodics-Neumann Maria, Mag.",
        "Smolle Josef, Dr.",
        "Sobotka Wolfgang, Mag.",
        "Solar Lola",
        "Sonnberger Peter, Dr.",
        "Soronics Franz",
        "Spannocchi Lelio, Dr.",
        "Spindelegger Erich, Ing.",
        "Stadler Astrid",
        "Stampler Franz",
        "Stangl Maria",
        "Stark Christoph",
        "Staudinger Rudolf",
        "Steibl Ridi Maria",
        "Steidl Albert, Dipl.-Kfm. Dr.",
        "Steinbauer Heribert",
        "Steindl Konrad",
        "Steinegger Otto, abs. iur.",
        "Steiner Josef",
        "Steiner Ludwig, Dipl.-Vw. Dr.",
        "Stemberger Josef, Dr.",
        "Stiegelbauer Sonja, Dr. siehe Moser-Starrach Sonja, Dr.",
        "Stocker Franz",
        "Stohs Herbert",
        "Stoll Franz",
        "Strachwitz Ernst, Dr.",
        "Strasser Georg, Dipl.-Ing.",
        "Stra\u00dfberger Josef",
        "Stricker Adolf",
        "Strobl Franz, Dipl.-Ing.",
        "Strommer Josef",
        "St\u00fcrgkh Barthold",
        "Suppan Walter",
        "Taschner Rudolf, Mag. Dr.",
        "Taus Josef, Dr.",
        "Tazreiter Josef",
        "Teller Dietrich, Dipl.-Kfm.",
        "Thoma Franz",
        "Thurner Leopold",
        "Titze Karl",
        "Toncic-Sorinj Lujo, Dr.",
        "Trau\u00dfnig Adolf",
        "Tschida Johann, Dipl.-Ing.",
        "Tschurtschenthaler Ignaz, Dipl.-Vw. Dr.",
        "Turkovic-Wendl Ingrid",
        "T\u00f6chterle Karlheinz, Dr.",
        "T\u00f6dling Othmar",
        "T\u00fcrtscher Anton",
        "Umenberger Ferdinand",
        "Url Karl, Ing.",
        "Vavrik Christoph, Mag.",
        "Vetter Georg, Dr.",
        "Vetter Gustav",
        "Vollmann Hans",
        "Vonwald Karl",
        "Wagner Johann",
        "Walla Michael",
        "Wallner Josef",
        "Wappis Elisabeth, Dr.",
        "Weber Franz, Dr.",
        "Wedenig Karl",
        "Wegscheider Susanne",
        "Weidenholzer Johann",
        "Weidinger Anton",
        "Weidinger Peter, Mag.",
        "Weinberger Lois",
        "Weindl Anton",
        "Weinmayer Leopold",
        "Weismann Leopold, Dr.",
        "Wei\u00df Ludwig, Dipl.-Ing. Dr.",
        "Wei\u00dfmann Walther, Dipl.-Kfm. Dr.",
        "Westreicher Hugo",
        "Wieser Helga",
        "Wiesinger Ernst, Dipl.-Ing.",
        "Wiesinger G\u00fcnther, Dr.",
        "Wimmersberger Anton",
        "Windisch Franz, Ing.",
        "Winkler Josef, Ing.",
        "Winsauer Werner, Dipl.-Ing.",
        "Winzig Angelika, Dr.",
        "Withalm Hermann, Dr.",
        "Witrisal Alois",
        "Wolf Johann",
        "Wolfmayr Andrea, Dr. siehe Wolfmayr Andrea, Mag. Dr.",
        "Wolfmayr Andrea, Mag. Dr.",
        "Wunder Gottfried",
        "W\u00f6ginger August",
        "W\u00f6lfler Alois, Mag.",
        "W\u00fchrer Alois",
        "Zarits Christoph",
        "Zernatto Christof, Dr.",
        "Zittmayr Hermann, Dipl.-Ing. Dr.",
        "Zweytick Johannes"
    ],
    "GRUeNE": [
        "Aslan Ayg\u00fcl Berivan, Mag.",
        "Blau-Meissner Freda siehe Meissner-Blau Freda",
        "Buchner Josef",
        "Erlinger Helga",
        "Fux Herbert",
        "Geyer Walter, Mag.",
        "Gf\u00f6hler Willibald, Mag.",
        "Glawischnig Eva, Dr. siehe Glawischnig-Piesczek Eva, Dr.",
        "Grandits Marijana, Mag.",
        "Harrich Holda",
        "Heindl Christine",
        "Hradecsni Bettina",
        "Jarmer Helene, Mag.",
        "Kuttner Astrid",
        "K\u00f6chl Matthias",
        "Meissner-Blau Freda",
        "M\u00fcckstein Eva, Dr.",
        "Neuroth Barbara, Mag.",
        "Puntscher Riekmann Sonja, Dr.",
        "Renoldner Severin, DDr.",
        "Schmid Julian, BA",
        "Srb Manfred",
        "Voggenhuber Johannes",
        "Windb\u00fcchler-Souschill Tanja",
        "Zaun Fritz"
    ],
    "SPOe": [
        "Ablinger Sonja",
        "Abraham Gerhard",
        "Achs Matthias",
        "Afritsch Josef",
        "Aigner Edmund",
        "Alberer Alois",
        "Albrecht Anneliese",
        "Androsch Hannes, Dipl.-Kfm. Dr.",
        "Androsch Maurice, Ing.",
        "Antoni Konrad",
        "Appel Rudolf",
        "Astl Johann",
        "Auer Josef, Mag.",
        "Babanitz Franz",
        "Bacher Walter",
        "Bauer Hannes, Dipl.-Kfm. Dr.",
        "Bauer Sophie",
        "Becher Ruth, Mag.",
        "Bechinie Robert, Dipl.-Kfm. Dr.",
        "Benya Anton",
        "Binder Gabriele siehe Binder-Maier Gabriele",
        "Binder-Maier Gabriele",
        "Bisjak Paul",
        "Blecha Karl",
        "Bl\u00fcmel Johann",
        "Brachmann Hans",
        "Braun Helmut",
        "Brauneis Walter",
        "Bregartner Karl",
        "Brennsteiner Anton",
        "Broda Christian, Dr.",
        "Broukal Josef",
        "Brunner Wanda",
        "Buchmayr Harry",
        "Buder Hannelore",
        "Buttinger Johann",
        "B\u00f6gl Hans",
        "B\u00f6hm Johann",
        "B\u00f6sch Herbert, Mag.",
        "Chaloupek Ferdinand",
        "Cs\u00f6rgits Renate",
        "Czernetz Karl",
        "Czerny Anna",
        "Czettel Adolf",
        "Czettel Hans",
        "Dallinger Alfred",
        "Deutsch Josef",
        "Dietrich G\u00fcnter",
        "Dobesberger Edith",
        "Dobnigg Karl",
        "Dohnal Johanna",
        "Draxler Hans",
        "Drozda Thomas, Mag.",
        "Dunst Verena",
        "Duzdar Muna, Mag.",
        "Eberhard Sepp",
        "Ecker Cornelia",
        "Eckhart Leopold",
        "Ederer Brigitte, Mag.",
        "Edler Josef",
        "Egg Herbert",
        "Ehmann Michael",
        "Eibegger Max",
        "Einwallner Reinhold, Ing.",
        "Elmecker Robert",
        "Emhart Maria",
        "Enge Franz",
        "Enser Maria",
        "Erasim Melanie, MSc",
        "Exler Rudolf",
        "Eypeltauer Beatrix, Dr.",
        "Fageth Ferdinand",
        "Fast Franziska",
        "Faul Christian",
        "Fauland Adolf",
        "Fazekas Hannes",
        "Feichtinger Elisabeth, BEd BEd",
        "Feichtinger Klaus Uwe, Mag. Dr.",
        "Fertl Rudolf, Dr.",
        "Firnberg Hertha, Dr. Dr. h.c.",
        "Fister Alfred",
        "Fleckl Anita",
        "Fleischmann Franz, Dr.",
        "Flossmann Ferdinanda",
        "Fl\u00f6ttl Karl",
        "Forsthuber Rudolf",
        "Franzmair Josef",
        "Freund Richard",
        "Friedl Klaudia",
        "Fr\u00f6mel Emmerich",
        "Fr\u00fchbauer Erwin",
        "Fr\u00fchwirth Michael",
        "Fuchs Brunhilde",
        "Fuhrmann Willi, Dr.",
        "Fux Franz",
        "F\u00fcller Christian",
        "Gaiswinkler Albrecht",
        "Gartlehner Kurt, Ing.",
        "Ga\u00dfner Kurt, Mag.",
        "Ga\u00e1l Anton",
        "Gebert Richard",
        "Geiger Leo",
        "Gessl-Ranftl Andrea",
        "Gf\u00f6ller Karl",
        "Giegerl Johann",
        "Gisel Alfred, Dr.",
        "Gmoser Rupert, DDr.",
        "Gossi Alois",
        "Grabner Arnold",
        "Grabner Heinz, Dipl.-Ing.",
        "Gradenegger Johannes, Dr.",
        "Gradischnik Reimar, Dr.",
        "Graenitz Ilona, Dipl.-Kfm.",
        "Gratz Leopold, Mag.",
        "Grebien Alois",
        "Greiner Karin, Mag.",
        "Grossmann Elisabeth, Mag.",
        "Gruber Renate",
        "Gschweidl Rudolf",
        "Guggenberger Walter, Mag.",
        "Gumplmayer Hans",
        "Gusenbauer-J\u00e4ger Marianne",
        "G\u00e4rtner Heinz",
        "Haager Christine",
        "Haas Herbert",
        "Haberl Alfred",
        "Haberzettl Wilhelm",
        "Hackenberg Heinrich",
        "Hagenhofer Marianne",
        "Hager Hanna",
        "Hagleitner Maria",
        "Haiden G\u00fcnter, Dipl.-Ing.",
        "Hakel Elisabeth",
        "Hammerschmid Sonja, Mag. Dr.",
        "Hanzlik Hella",
        "Haselwanter Ernst, Dr.",
        "Hasler Sieghard, Ing.",
        "Hatzl Johann",
        "Hawlicek Hilde, Dr.",
        "Hechtl Johann",
        "Heigl Hans",
        "Heigl Johann siehe Heigl Hans",
        "Heinz Roman",
        "Hell Johann",
        "Hellwagner Franz",
        "Helmer Oskar",
        "Herke Hans",
        "Hesele Hans, DDr.",
        "Hesoun Josef",
        "Hessl Hermann",
        "Hieden Helga, Dr. siehe Hieden-Sommer Helga, Dr.",
        "Hieden-Sommer Helga, Dr.",
        "Hillegeist Friedrich",
        "Hinterleithner Ignaz",
        "Hirscher Josef",
        "Hlavac Elisabeth, Dr.",
        "Hobl Hans, Ing.",
        "Hochmair Fritz",
        "Hochstetter-Lackner Irene",
        "Hoffmann Friedrich",
        "Hofmann Harald",
        "Hofstetter Erich",
        "Holoubek Karl",
        "Holzfeind Edmund",
        "Holzinger Daniela, BA siehe Holzinger-Vogtenhuber Daniela, BA",
        "Holzinger-Vogtenhuber Daniela, BA",
        "Holzleitner Eva Maria, BSc",
        "Hopfer Sepp",
        "Horejs Karl",
        "Horn Alfred",
        "Horn Josef",
        "Horr Franz",
        "Horvath Waltraud, Mag. siehe Sch\u00fctz Waltraud, Mag.",
        "Hoscher Dietmar, Mag.",
        "Huber Anna",
        "Hums Franz",
        "Hursky Christian",
        "Hutterer Klaus, Dipl.-Ing. Dr.",
        "H\u00e4user Rudolf, Ing.",
        "H\u00e4uslmayer Ferdinand, Dr.",
        "H\u00f6ll Johann",
        "Jankowitsch Peter, Dr.",
        "Janschitz Robert",
        "Jessner Max",
        "Jiricek Hans",
        "Jochmann Rosa",
        "Jonas Franz",
        "Jungwirth Hans",
        "J\u00e4ger Inge",
        "Kaipel Erwin, Ing.",
        "Kapaun Heinz, Dr.",
        "Karl Elfriede",
        "Karlsson Irmtraut, Dr.",
        "Katzengruber Franz",
        "Katzian Wolfgang",
        "Keck Dietmar",
        "Keller Heinrich, Dr.",
        "Keppelm\u00fcller Peter, Dipl.-Ing. Dr.",
        "Kerschbaum Josef",
        "Kerstnig Hans, Dr.",
        "Kiermaier G\u00fcnter",
        "Kirchgatterer Franz",
        "Kittl Eduard",
        "Klausberger Gerhard, Mag.",
        "Klein-L\u00f6w Stella, Dr.",
        "Kleiner Viktor, Dr.",
        "Klenner Fritz",
        "Klug Gerald, Mag.",
        "Knechtelsdorfer Karl",
        "Knes Wolfgang",
        "Knoll Gertraud, Mag.",
        "Knoll-Lacina Gertraud, Mag. siehe Knoll Gertraud, Mag.",
        "Kokail Siegfried",
        "Koller Emmerich",
        "Kollmann Alfred",
        "Kollross Andreas",
        "Konecny Albrecht",
        "Konecny Helene",
        "Konir Fritz",
        "Konrad Helga, Dr.",
        "Koppler Erhard",
        "Koref Ernst, Dr.",
        "Kostelecky Josef",
        "Kostroun Ludwig",
        "Kottek Franz",
        "Kovacevic Christian",
        "Krammer Christa, Dr.",
        "Krammer Karl",
        "Kratky Josef",
        "Kreisky Bruno, Dr.",
        "Kren Maria",
        "Krenn Erika",
        "Krenn Willibald, Ing.",
        "Krisch Karl",
        "Krismanich Elfriede, Mag.",
        "Krist Hermann",
        "Kriz Georg",
        "Krones Hilde",
        "Kr\u00e4uter G\u00fcnther, Dr.",
        "Kr\u00e4utl Adolf",
        "Kuba Heinrich",
        "Kubitschek Maria, Mag.",
        "Kucharowits Katharina",
        "Kummerer Werner, Dipl.-Ing.",
        "Kunst Josef, Ing.",
        "Kunst\u00e4tter Harald",
        "Kuzdas Hubert, Ing. Mag.",
        "Kysela Karl",
        "K\u00f6ck Franz",
        "K\u00f6fer Gerhard",
        "K\u00f6nigsberger-Ludwig Ulrike",
        "K\u00f6rner Theodor, Dr. h.c.",
        "K\u00f6teles Johann",
        "K\u00f6vari Susanne",
        "Lacina Ferdinand, Dipl.-Kfm.",
        "Lackner Hermann",
        "Lackner Manfred",
        "Lagger Hans",
        "Laimer Robert",
        "Lanc Erwin",
        "Lapp Christine, Mag., MA",
        "Lausecker Karl",
        "Lehr Friedrich",
        "Leichtfried J\u00f6rg, Mag.",
        "Leithenmayr Hermann",
        "Lenzi Josef, Dr.",
        "Leser Ludwig, Dr. h.c.",
        "Leutner Richard, Dr.",
        "Libal Otto",
        "Linder Anton",
        "Lindner Mario",
        "Lipitsch Hermann",
        "Liwanec Willi",
        "Lohfeyer Rosa, Mag.",
        "Ludwig Hans",
        "Lukas Leo",
        "Luptowits Michael",
        "L\u00f6schnak Franz, Dr.",
        "Maderner Josef, DDr.",
        "Maderthaner Michael",
        "Maier Kurt",
        "Maisel Karl",
        "Marchner Rudolf",
        "Margreiter Doris",
        "Marizzi Peter",
        "Mark Karl",
        "Marsch Fritz",
        "Matejcek Josef",
        "Matzenauer Hans",
        "Mayr Hans",
        "Mentasti Alois",
        "Messner Evelyn, Mag.",
        "Metzker Maria",
        "Migsch Alfred, Dr.",
        "Miksch Karl",
        "Mistinger Leo",
        "Modl Josef",
        "Moik Wilhelmine",
        "Mondl Walter",
        "Moser Josef",
        "Mrkvicka Franz",
        "Murowatz Lona",
        "Muttonen Christine, Mag.",
        "M\u00e4rk Elisabeth",
        "M\u00fchlbacher Kurt",
        "M\u00fcller Franz",
        "M\u00fcller Karl Gerfried",
        "M\u00fcller Lothar, Dr.",
        "Nedwed Ernst, Ing.",
        "Neidhart Rolf, Dr.",
        "Nekula Kurt, MA, MAS",
        "Neugebauer Max, Dr.",
        "Neuhauser Ernst",
        "Neuwirth Karl",
        "Ninaus Josef",
        "Nittel Heinz",
        "Nussbaum Verena, Mag.",
        "N\u00fcrnberger Rudolf",
        "Oberhaidinger Georg",
        "Offenbeck Jolanda, Dr.",
        "Olah Franz",
        "Onodi Heidemaria",
        "Ortner Josef",
        "Pansi Herbert",
        "Parfuss Ludmilla",
        "Pawlik Annemarie",
        "Pay Michael",
        "Peck Josef",
        "Pecker Helene siehe Konecny Helene",
        "Pecker-Z\u00f6rner Helene siehe Konecny Helene",
        "Petschnik Viktor",
        "Pfeffer Karl",
        "Pfeffer Katharina",
        "Pfeifer Josef",
        "Pichler Adam",
        "Pichler Franz",
        "Piller Ernst",
        "Pittermann Bruno, DDr.",
        "Plaimauer Stefan",
        "Plessl Rudolf",
        "Pokorny Maria",
        "Pollak Marianne",
        "Populorum Adolf",
        "Porges Alfred",
        "Posch Paul",
        "Praher Adelheid",
        "Prechtl Fritz",
        "Preiner Erwin",
        "Prei\u00df Kurt, Dr.",
        "Prettenthaler Sylvia siehe Rinner Sylvia",
        "Preu\u00dfler Kurt",
        "Probst Otto",
        "Proft Gabriele",
        "Proksch Anton",
        "Pr\u00e4hauser Stefan",
        "Puswald Christian, Dr.",
        "P\u00f6der Rudolf",
        "P\u00f6lz Johann",
        "P\u00f6lzer Johann",
        "Rada Robert, Dr.",
        "Radinger Stephan",
        "Rauscher Franz",
        "Rechberger Alois",
        "Reheis Gerhard",
        "Reicht Alois",
        "Reinhart Karl, Dr.",
        "Reismann Edmund",
        "Remplbauer Wilhelm",
        "Rendi-Wagner Pamela, Dr., MSc",
        "Renner Karl, Dr.",
        "Renner Walter",
        "Resch Walter",
        "Ressel Hans-Joachim, Ing.",
        "Rieder Josef, Dr.",
        "Riedler Wolfgang, Dr.",
        "Riepl Franz",
        "Rinner Sylvia",
        "Robak Friedrich",
        "Roithner Berthold",
        "Rom Martin",
        "Roppert Alois",
        "Rosenberger Paul",
        "Rudas Laura, Mag.",
        "Ruhaltinger Franz",
        "R\u00f6sch Otto",
        "R\u00fcck Rosa",
        "R\u00fcckl Engelbert",
        "Sacher Ewald",
        "Salcher Herbert, Dr.",
        "Sallaberger G\u00fcnther",
        "Samwald Franz",
        "Sandler Birgit Silvia",
        "Schabh\u00fcttl J\u00fcrgen",
        "Scharer Erika",
        "Scharf Erwin",
        "Schasching Beate",
        "Schatz Sabine",
        "Scheibengraf Heinrich, Ing.",
        "Scheiblin Anton",
        "Schemer Stefan",
        "Scheucher Reinhold",
        "Schickhofer Michael, Mag.",
        "Schieder Peter siehe Schieder Peter, Dr. h.c.",
        "Schlager Josef",
        "Schl\u00f6gl Karl, Mag.",
        "Schmidl Josef",
        "Schmidt Erich, Dr.",
        "Schmidtmeier Herbert",
        "Schm\u00f6lz Johann",
        "Schneeberger Pius",
        "Schnell Hermann, Dr.",
        "Schober Albin",
        "Scholger Walter",
        "Scholten Rudolf, Dr.",
        "Schopf Walter",
        "Schramm Erwin",
        "Schranz Edgar, Dr.",
        "Schwemlein Emmerich",
        "Sch\u00e4rf Adolf, Dr.",
        "Sch\u00f6npass Rosemarie",
        "Sch\u00fcrer Franz",
        "Sch\u00fctz Waltraud, Mag.",
        "Seda Erika, Dr.",
        "Seel Helmut, Dr.",
        "Seiler Hilde",
        "Seilinger Josef",
        "Seitz Karl",
        "Sekanina Karl",
        "Sigl Robert",
        "Singer Rudolf",
        "Sinowatz Fred, Dr.",
        "Skritek Otto",
        "Slavik Felix",
        "Smejkal Ingrid",
        "Speiser Paul",
        "Spielb\u00fcchler Karl",
        "Spindelberger Erwin",
        "Springschitz Stefan",
        "Staffa Franz",
        "Stampler Andreas",
        "Staribacher Josef, Dipl.-Vw. Dr.",
        "Stauber Peter",
        "Steier Gerhard",
        "Steinbach Ernst",
        "Steindl Katharina",
        "Steiner Josef",
        "Steinhuber Josef siehe Steinhuber Sepp",
        "Steinhuber Sepp",
        "Steininger Franz",
        "Steinma\u00dfl Erwin",
        "Steyrer Kurt, Dr.",
        "Ste\u00dfl Sonja, Mag.",
        "Ste\u00dfl-M\u00fchlbacher Sonja, Mag. siehe Ste\u00dfl Sonja, Mag.",
        "Stika Felix",
        "Stippel Johann, Dr.",
        "Stocker Helmuth",
        "Strache Max",
        "Strasser Peter",
        "Strobl Robert",
        "Str\u00f6er Alfred",
        "St\u00f6ger Alois, dipl\u00f4m\u00e9",
        "St\u00f6gner Gregor",
        "Suchanek Erich",
        "Svihalek Friedrich",
        "Szep Irene",
        "Tegischer Brigitte",
        "Teschl Alfred",
        "Thalhammer Rudolf",
        "Tieber Herbert, Dipl.-Vw.",
        "Tirnthal Rudolf",
        "Toifl-Campregher Sonja, Dr.",
        "Tonn Rudolf",
        "Traxler Gabrielle",
        "Treichl Leonhard",
        "Troch Harald, Dr.",
        "Troll Karl",
        "Trunk Melitta, Mag.",
        "Truppe Paul",
        "Tschadek Otto, Dr.",
        "Tull Stephan, Dr.",
        "Tychtl Gerald, Ing.",
        "Uhlir Robert",
        "Ulbrich Ernst",
        "Unterrainer Maximilian, Mag. (FH)",
        "Veleta Josef",
        "Veselsky Ernst Eugen, Dr.",
        "Voithofer Josef",
        "Voraberger Hubert",
        "Vranitzky Franz, Dipl.-Kfm. Dr.",
        "Walcher Franz",
        "Waldbrunner Karl, Dipl.-Ing.",
        "Wallisch Paula",
        "Wallner Kurt",
        "Walther Heidrun",
        "Wastl Hans",
        "Weber Rosa",
        "Wechner Hedwig",
        "Wedenig Ferdinand",
        "Weihs Oskar, Dipl.-Ing. Dr.",
        "Weikhart Eduard",
        "Weinberger Helmut",
        "Weisz Robert",
        "Wendl Sepp",
        "Weninger Hannes",
        "Widmayer Heinrich",
        "Wielandner Hermann",
        "Wille Josef",
        "Willinger Franz, Ing.",
        "Wimberger Alois",
        "Wimmer Petra",
        "Wimmer Rainer",
        "Wimmer Walter",
        "Windsteig Johann",
        "Winkler Ernst",
        "Winkler Herta",
        "Winter Otto, Dr.",
        "Winterer Franz",
        "Wodica Anton",
        "Wolf Helmut",
        "Wolf Leopold",
        "Wolf Richard",
        "Wolfmayr Hans",
        "Wondrack Gertrude",
        "Woschitz Anton",
        "Wuganigg Viktor",
        "W\u00f6rndl Heidelore",
        "Yildirim Selma, Mag.",
        "Yilmaz Nurten",
        "Zach Alexander",
        "Zankl Hubert",
        "Zechner Leopold, Dr.",
        "Zechtl Rupert",
        "Zingler Franz",
        "Zipser Ella",
        "Zodl Ernst",
        "\u00dcbeleis Vinzenz"
    ],
    "STRONACH": [
        "Lintl Jessi, Dr.",
        "Stronach Frank",
        "Tadler Erich",
        "Vetter Georg, Dr."
    ],
    "andere": [
        "Abram Simon",
        "Adler Friedrich, Dr.",
        "Adlersfl\u00fcgel Mathias",
        "Aichhorn Karl",
        "Aigner Josef, Dr.",
        "Allina Heinrich",
        "Altenbacher Franz",
        "Aman Sepp",
        "Amlacher Michael",
        "Angerer Hans, Dr.",
        "Appel Karl, Dr.",
        "Auinger Johann",
        "Austerlitz Friedrich",
        "A\u00dfmann Alwin",
        "Barm\u00fcller Thomas, Mag.",
        "Bauer Alois",
        "Bauer Franz",
        "Bauer Otto, Dr.",
        "Baumg\u00e4rtel Emil",
        "Berger Johann",
        "Bichl Felix",
        "Bichler Karl",
        "Binder Franz",
        "Birbaumer Franz",
        "Bi\u00dfmann Martha, Dipl.-Ing. (FH)",
        "Bl\u00f6chl Johann",
        "Bl\u00fcnegger Anton",
        "Boschek Anna",
        "Brachmann Hans",
        "Bretschneider Ludwig August",
        "Brinnich Franz",
        "Buchberger Adalbert, Dipl.-Ing. Dr.",
        "Buchinger Rudolf",
        "Buchleitner Johann",
        "Buchner Josef",
        "Buresch Karl, Dr.",
        "Burgmann Rudolf",
        "Burgstaller Alois",
        "Burgstaller Paul",
        "B\u00f6hler Georg",
        "B\u00f6hm Johann",
        "B\u00f6sch Karl",
        "Clessin Heinrich, abs. iur.",
        "Czernin Ottokar",
        "Danneberg Robert, Dr.",
        "Dersch Mathias",
        "Deutsch Julius, Dr.",
        "Dewaty Hubert",
        "Dietrich Dominikus",
        "Dinghofer Franz, Dr.",
        "Diwald Leopold",
        "Domes Franz",
        "Doppler Leopold",
        "Dostal Hubert, Dr.",
        "Drexel Karl, DDr.",
        "Duda Adolf",
        "Duld Matthias",
        "Duscher Matthias",
        "Ebenbichler Gerhard",
        "Ebner Anton",
        "Ebner Hans",
        "Eder-Lindner Astrid Monika, Dr.",
        "Edlinger Wolfgang",
        "Egger Bernhard",
        "Eichinger Johann",
        "Eigruber Hermann",
        "Eisenhut Josef",
        "Eisler Arnold, Dr.",
        "Eldersch Matthias",
        "Ellenbogen Wilhelm, Dr.",
        "Ellend Bernhard",
        "Ellmerer Hans",
        "Elser Viktor",
        "Elshuber August",
        "Ertl Ferdinand",
        "Ertl Franz",
        "Fahrner Anton",
        "Falger Nikolaus",
        "Falle Anton",
        "Fink Jodok, Dr. h.c.",
        "Fischer Christian",
        "Fischer Ernst",
        "Fischer Hermann",
        "Flossmann Ferdinanda",
        "Fohringer Josef",
        "Foppa Hermann",
        "Forstner August",
        "Frank Felix, Dr.",
        "Freundlich Emmy",
        "Frischenschlager Friedhelm, Dr.",
        "Fr\u00fchwirth Michael",
        "Furreg Lotte",
        "F\u00f6dermayr Florian",
        "Gabriel Josef",
        "Gangl Michael",
        "Gasselich Anton, Dr.",
        "Geisler Simon",
        "Genner Laurenz",
        "Geyer Hermann",
        "Ge\u00dfl Josef",
        "Gierlinger Balthasar",
        "Gimpl Georg, Dr.",
        "Gl\u00f6ckel Otto",
        "Grabenhofer Gottlieb",
        "Graf Florian",
        "Grailer Iring, Dr.",
        "Gredler Willfried, Dr.",
        "Gritschacher Josef",
        "Gruber Alois",
        "Gruber Josef",
        "Gruber Michael",
        "Gruber Rudolf",
        "Gr\u00f6ger Florian",
        "Gr\u00f6\u00dfbauer Philipp",
        "G\u00fcrtler Alfred, Dr.",
        "G\u00fcrtler Johann",
        "Hafner Josef",
        "Haider Franz",
        "Hainzl Josef",
        "Hammerschmid Josef",
        "Hammerstorfer Hans",
        "Hampel Ernst, Dr.",
        "Hanusch Ferdinand",
        "Hareter Alexander",
        "Hartleb Karl",
        "Hartmann Josef",
        "Hasenauer Bartholom\u00e4us",
        "Haueis Alois",
        "Hauser Johann Nepomuk",
        "Hautmann Marie",
        "Heigl Josef",
        "Heinl Eduard, Dr. h.c.",
        "Heinz Karl",
        "Heitzinger Johann",
        "Hermann Hermann",
        "Herzele Max",
        "Heuberger August",
        "Hirsch Ernst",
        "Hofer Franz, Dr.",
        "Hofer Hans",
        "Hohenberg Karl",
        "Hollersbacher Josef",
        "Holzinger Daniela, BA siehe Holzinger-Vogtenhuber Daniela, BA",
        "Holzinger-Vogtenhuber Daniela, BA",
        "Honner Franz",
        "Horvatek Norbert",
        "Hosch Kaspar",
        "Hryntschak Alexander, Dr.",
        "Hubmann Georg",
        "Hueber Anton",
        "Hueber Franz, Dr.",
        "Huemer Oskar",
        "H\u00f6chtl Leopold",
        "H\u00f6lzl Anton Franz",
        "Illmer Hans, Ing.",
        "Irsa Karl",
        "Janecek Johann",
        "Janicki Oskar",
        "Jerzabek Anton, Dr.",
        "Jiricek Hans",
        "Jury Josef",
        "Jutz Michael",
        "Kampitsch Julius",
        "Kandutsch J\u00f6rg, Dr.",
        "Kapral Emma",
        "Kern Felix",
        "Kienb\u00f6ck Viktor, Dr.",
        "Kindl Wilhelm",
        "Klautzer Franz",
        "Kleissl Robert",
        "Kletzmayr Hermann",
        "Klieber Mauritius",
        "Klimann Thomas",
        "Klimberger Karl",
        "Klug Simon",
        "Kneu\u00dfl Erich, Dr.",
        "Knosp Josef",
        "Kolassa Viktor, Dr.",
        "Kolb Franz, Dr.",
        "Kollarz Friedrich",
        "Kollmann Josef",
        "Kopf Rudolf, Dr.",
        "Koplenig Johann",
        "Koref Ernst, Dr.",
        "Kraft Emil",
        "Kraus Herbert Alois, Dr.",
        "Kraus Josef",
        "Kreutzberger Friedrich",
        "Kroboth Rudolf",
        "Kr\u00fczner Alexander, Dr.",
        "Kunschak Leopold",
        "K\u00f6fer Gerhard",
        "K\u00f6nig Berthold",
        "K\u00f6nigshofer Werner, DDr.",
        "K\u00f6stler Maria",
        "Lackner Friedrich, Ing.",
        "Lagger Hans",
        "Laimer Hans",
        "Lampl Franz",
        "Lanner Anton",
        "Laser Adolf",
        "Lengauer Josef",
        "Lenz Hans",
        "Leskovar Adolf",
        "Leutgeb Leopold",
        "Leuthner Karl",
        "Lichtenegger Fritz",
        "Lieschnegg Karl",
        "Lintl Jessi, Dr.",
        "Lump Anton",
        "Luttenberger Franz",
        "Maier Anton, Dr.",
        "Maier Franz",
        "Manhalter Rudolf",
        "Markschl\u00e4ger Rudolf",
        "Marolt Heinz Anton",
        "Mataja Heinrich, Dr.",
        "Mayer Josef",
        "Mayr Michael, Dr.",
        "Mayr Otto",
        "Mayrhofer Franz",
        "Mayrhofer Hans",
        "Mei\u00dfner Theodor",
        "Mentil Hermann",
        "Miklas Wilhelm",
        "Morawitz Hans",
        "Mo\u00dfhammer Franz",
        "Muchitsch Hans",
        "M\u00f6bius Reinhold, Dr.",
        "M\u00fcller Adolf",
        "M\u00fcllner Franz, Dr.",
        "M\u00fcllner Hans",
        "Neuhofer Michael",
        "Neumann Anton",
        "Neust\u00e4dter-St\u00fcrmer Odo",
        "Neuwirth Thomas, abs. iur.",
        "Niedrist Karl",
        "Oberegger Josef, Ing.",
        "Odehnal Franz, Dr.",
        "Oelzelt Franz",
        "Olah Franz",
        "Pargfrieder Leopold",
        "Parrer Franz",
        "Partik Matthias",
        "Paulitsch Michael",
        "Pauly Max",
        "Pazelt Josef",
        "Peschel Brigitte",
        "Peter Adolf",
        "Pfeifer Helfried, Dr.",
        "Pichler Josef",
        "Pick Karl",
        "Pirchegger Anton",
        "Pischitz Georg",
        "Pistor Felix",
        "Plasser Franz",
        "Polke Emil",
        "Popp Adelheid",
        "Prentl Josef",
        "Probst Anton",
        "Prodinger Hans",
        "Proft Gabriele",
        "P\u00f6lzer Johann",
        "Raab Julius, Ing. DDDr.",
        "Ramek Rudolf, Dr.",
        "Rammer Hans",
        "Raser Josef",
        "Rauhofer Josef, abs. iur.",
        "Rauscha Julie",
        "Reimann Viktor, Dr.",
        "Reiner Fritz, Ing.",
        "Renner Florian",
        "Renner Karl, Dr.",
        "Resch Josef, Dr.",
        "Richter Paul",
        "Rieger Eduard",
        "Rintelen Anton, Dr.",
        "Roth Hans",
        "Rozenits Demetrius",
        "Rudel-Zeynek Olga",
        "R\u00f6sch Eduard",
        "Sailer Georg",
        "Sassik Raimund",
        "Scharf Erwin",
        "Scharfegger Ambros",
        "Schauer-Schoberlechner Johannes, Dr.",
        "Scheffauer Theodor",
        "Scheibein Wilhelm",
        "Schein Leopold",
        "Scheuch Robert, Dipl.-Ing. Dr.",
        "Schiegl Wilhelm",
        "Schirmer Aloisia",
        "Schlager Rudolf",
        "Schlesinger Paul Johannes",
        "Schlesinger Therese",
        "Schmidt Friedrich",
        "Schmidt Josef",
        "Schmitz Richard",
        "Schneeberger Pius",
        "Schneider Emil, Dr.",
        "Schneider Maria, Dr.",
        "Schneidmadl Heinrich",
        "Schober Johannes, abs. iur. DDDr. h.c",
        "Schoiswohl Michael",
        "Schorsch Johann",
        "Schreiner Anton",
        "Schulz Hermann",
        "Schumacher Franz, Dr.",
        "Schuschnigg Kurt, Dr.",
        "Sch\u00f6n Franz",
        "Sch\u00f6nbauer Ernst, DDr.",
        "Sch\u00f6nsteiner Friedrich",
        "Sch\u00f6pfer Aemilian, Dr.",
        "Sch\u00fcrff Hans, Dr.",
        "Seidel Amalie",
        "Seidel Richard, Ing.",
        "Seidl Georg",
        "Seipel Ignaz, Dr.",
        "Seitz Karl",
        "Sever Albert",
        "Siegl Josef",
        "Skaret Ferdinand",
        "Smitka Johann",
        "Spalowsky Franz",
        "Starhemberg Ernst R\u00fcdiger",
        "Stein Viktor",
        "Steinegger Hans",
        "Steinegger Otto, abs. iur.",
        "Steiner Franz",
        "Stempfer Georg",
        "Stendebach Max",
        "Stika Felix",
        "Strachwitz Ernst, Dr.",
        "Stradal Emmy",
        "Straffner Sepp, Dr.",
        "Streeruwitz Ernst",
        "Strie\u00dfnigg Karl",
        "Strohmaier Vinzenz",
        "Strunz Rudolf",
        "St\u00f6ckler Josef",
        "St\u00f6gner Hans, Dr.",
        "St\u00fcber Fritz, Dr.",
        "Swoboda Karl, Dr.",
        "Sylvester Hans, Ing.",
        "S\u00e9gur August",
        "Tadler Erich",
        "Tauschitz Stephan, Ing.",
        "Teufl Josef",
        "Thaler Andreas",
        "Thoma Franz",
        "Thullner Johann",
        "Tomschik Josef",
        "Traxler Franz",
        "Traxler Gabrielle",
        "Tull Stephan, Dr.",
        "Tuller Ludwig",
        "Tusch Maria",
        "Ulrich Heinrich",
        "Unterberger Franz",
        "Unterkircher Peter",
        "Ursin Josef, Dr.",
        "Vaugoin Carl",
        "Viehauser Jakob",
        "Vinzl Josef",
        "Volker Otto",
        "Volkert Karl",
        "Waber Leopold, Dr.",
        "Wache Wilhelm",
        "Wagner Johann",
        "Wagner Josef",
        "Wagner Josef, Dr.",
        "Wagner Otto, Dr.",
        "Waihs Erwin, Dr.",
        "Wallisch Koloman",
        "Wancura Johann Thomas",
        "Waneck Friedrich, Dr.",
        "Weber Anton",
        "Weidenhoffer Emanuel, Dr.",
        "Weidenholzer Johann",
        "Weigl Richard, Ing.",
        "Weinhandl Alois",
        "Weiser Kajetan",
        "Weiskirchner Richard, Dr. Dr. h.c.",
        "Wei\u00df Josef",
        "Wendl Franz",
        "Wense Ernst",
        "Werner Max",
        "Widholz Laurenz",
        "Wiedenhofer Josef",
        "Wieninger Josef",
        "Wiesmaier Josef",
        "Wimmer Elias",
        "Wimmer Johann",
        "Wimmer Matthias",
        "Winkler Franz, Ing.",
        "Winsauer Ernst, Ing.",
        "Winter Susanne, Dr.",
        "Witternigg Josef",
        "Witzany Franz",
        "Witzany Hans",
        "Wollek Richard",
        "Wollinger Karl",
        "Wotawa August, Dr.",
        "Wunsch Oskar",
        "Zangel Josef",
        "Zarboch Rudolf",
        "Zauner Josef",
        "Zehetgruber Franz",
        "Zeidler Viktor, Dr.",
        "Zeillinger Gustav",
        "Zeininger Sylvester",
        "Zelenka Franz",
        "Zingl Josef",
        "Zwanzger Johann",
        "Zwenk Heinrich"
    ]
}
